<template>
  <div>
    <!-- lead-search -->
    <div
      class="lead-search-area"
      v-if="$storage.get('auth').user.user_type != 2"
    >
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-5">
          <div class="lead-search">
            <form action="">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Agnet</label>
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="filterSearch.agent"
                  v-on:change="updateData('agent')"
                >
                  <option value="">Select Agent</option>
                  <option
                    v-for="emp in allEmployees"
                    :key="emp.id"
                    :value="emp.id"
                  >
                    {{ emp.name }}
                  </option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-5">
          <div class="lead-search">
            <form action="">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Teams</label>

                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="filterSearch.team"
                  v-on:change="updateData('team')"
                >
                  <option value="">Select Team</option>
                  <option
                    v-for="team in allTeams"
                    :key="team.id"
                    :value="team.tm_id"
                  >
                    {{ team.tm_name }}
                  </option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-2">
          <div class="lead-search">
            <a class="refresh" @click="refresh"
              ><img class="img-fluid" src="assets/img/refresh.svg" alt="" />
              Refresh</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- lead-search -->

    <!-- leads-table-area  -->
    <div class="leads-table-area task-table">
      <div class="timeline">
        <button
          type="button"
          v-bind:class="$store.state.taskFilter == 'today' ? 'current' : ''"
          v-on:click="updateData('today')"
        >
          <span class="today">Today</span>
        </button>
        <button
          type="button"
          v-bind:class="$store.state.taskFilter == 'month' ? 'current' : ''"
          v-on:click="updateData('month')"
        >
          <span class="today">This Month</span>
        </button>
        <button
          type="button"
          v-bind:class="$store.state.taskFilter == 'custom' ? 'current' : ''"
        >
          <date-range-picker
            :opens="'left'"
            :ranges="false"
            :locale-data="{ format: 'yyyy-mm-dd' }"
            v-model="filterSearch.customDate"
            @update="updateData('custom')"
          ></date-range-picker
          ><span class="month">Custom Date</span>
        </button>
      </div>

      <table class="display" id="tasks_table">
        <thead>
          <tr>
            <th>Task ID</th>
            <th>Task Date</th>
            <th>Customer Name</th>
            <th>Sales Rep</th>
            <th>BDC Rep</th>
            <th>Task Assigned To</th>
            <th>Task Description</th>
            <th>Status</th>

            <!-- <th>Task Id</th>
            <th>Task Name</th>
            <th>Leadsheet</th>
            <th>Agent</th>
            <th>Date</th>
            <th>Scheduled For</th>
            <th>Status</th> -->
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- leads-table-area  -->
    <!-- appointment  deatils Modal -->
    <div
      class="modal fade"
      id="tasktDetail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="vehicleDetailModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <a class="delas-colse close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Closex.svg"
                alt=""
            /></span>
          </a>
          <div class="modal-body Intersets-modal">
            <div class="row">
              <div class="col-12">
                <div class="details-modal">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Description</td>
                        <td v-html="task_detail.a_desc"></td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>{{ task_detail.a_date }}</td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>{{ task_detail.a_time }}</td>
                      </tr>
                      <tr>
                        <td>Scheduled By</td>
                        <td>{{ task_detail.a_by }}</td>
                      </tr>
                      <tr>
                        <td>Scheduled For</td>
                        <td>{{ task_detail.a_for }}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{{ task_detail.a_status }}</td>
                      </tr>
                      <tr>
                        <td>Lead Id</td>
                        <td>
                          <a
                            href="#"
                            v-on:click.stop.prevent="
                              redirectIt(task_detail.ta_lead_id)
                            "
                          >
                            {{ task_detail.ta_lead_id }}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Created Date</td>
                        <td>{{ task_detail.created_date }}</td>
                      </tr>
                      <br />
                      <div class="row gotolead">
                        <a
                          href="#"
                          v-on:click.stop.prevent="
                            redirectIt(task_detail.ta_lead_id)
                          "
                          class="rem-btn"
                          ><span> Go to lead</span></a
                        >
                      </div>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- <div class="col-12 text-center mt-3">
                <button class="rem-btn" style="display: initial">Select</button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- appointment  deatils Modal  -->
  </div>
</template>


<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  name: "AllTasks",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      tasks: null,
      filterSearch: {
        duration: "month",
        agent: "",
        team: "",
        customDate: {},
        from: "",
        to: "",
      },
      task_detail: {},
      allEmployees: [],
      allTeams: [],
    };
  },
  created() {
    this.getAllEmployees();
    this.getAllTeams();
    if (
      this.$route.query.duration != undefined &&
      this.$route.query.duration != null &&
      this.$route.query.duration != "undefined" &&
      this.$route.query.duration != ""
    ) {
      this.filterSearch.duration = this.$route.query.duration;
    }
  },
  methods: {
    refresh() {
      (this.filterSearch = {
        duration: "month",
        agent: "",
        team: "",
        customDate: {},
        from: "",
        to: "",
      }),
        $("#tasks_table").dataTable().fnDraw();
    },
    redirectIt(id) {
      $("#tasktDetail").modal("hide");
      setTimeout(() => {
        this.$router.push("/lead-detail/" + id);
      }, 400);
    },
    taskDetail(id) {
      axios
        .get("/task/detail/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.task_detail = data.success;
          }
          $("#tasktDetail").modal("show");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllEmployees() {
      axios
        .get("all_employees", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.allEmployees = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllTeams() {
      axios
        .get("teams", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.allTeams = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateData(val) {
      if (val == "agent") {
        this.filterSearch.team = "";
      } else if (val == "team") {
        this.filterSearch.agent = "";
      } else if (val == "custom") {
        let startDate = this.filterSearch.customDate.startDate;
        let endDate = this.filterSearch.customDate.endDate;
        this.$store.state.task_from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        this.$store.state.task_to = moment(new Date(endDate)).format(
          "YYYY-MM-DD"
        );
        this.$store.commit("updateTaskFilter", "custom");
      } else {
        this.$store.commit("updateTaskFilter", val);
      }
      $("#tasks_table").dataTable().fnDraw();
    },
  },
  // watch: {
  //     "$route.query.type": function () {
  //         this.updateData()
  //     },
  //     "filterSearch.keyword": function() {
  //         this.updateData()
  //     },

  // },
  mounted() {
    let v = this;
    $("#tasks_table")
      .addClass("nowrap")
      .dataTable({
        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        stateSave: true,
        order: [[1, "desc"]],
        ajax: {
          url: axios.defaults.baseURL + "tasks_list",
          type: "POST",
          data: function (d) {
            d.agent = v.filterSearch.agent;
            d.tm_id = v.filterSearch.team;
            d.duration = v.$store.state.taskFilter;
            d.from = v.$store.state.task_from;
            d.to = v.$store.state.task_to;
            d.type = v.$route.query.type;
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        },
        columnDefs: [
          {
            targets: [3, 4, 5, 6],
            orderable: false,
          },
        ],
      });

    $("#tasks_table").on("click", "tr", function () {
      var id = $(this).find(".tskdetail").data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        // v.taskDetail(id);
        v.redirectIt(id);
      }
    });
  },
};
</script>

<style scoped>
.task-table .timeline,
.addcustomer-table .timeline {
  margin-top: 103px;
  position: relative;
  top: 15px;
  float: right;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  width: 330px;
  margin: 0 auto;
}
.vue-daterange-picker {
  width: 100%;
}
@import "../../assets/css/dataTables.css";
</style>
